<script setup>
definePageMeta({
  layout: 'moto-layout',
});

const backend = useBackend();
const route = useRoute();
const router = useRouter();

const itemsPerPage = 18;
const currentPage = computed(() => parseInt(route.query.page) || 1);
const searchQuery = computed(() => route.query.search || '');
const pageData = reactive({
  articles: { items: [] },
});

const totalItems = ref(0);
const { data: articlesData, pending: articlesPending, error: articlesError } = await useAsyncData(
  `search-${searchQuery.value}-${currentPage.value}`,
  () => {
    const backendPage = currentPage.value - 1;
    return backend.getMotoSearchPageData(searchQuery.value, backendPage);
  },
  {
    watch: [currentPage, searchQuery],
  }
);

watch(
  () => articlesData.value,
  (newVal) => {
    if (newVal && newVal.data && newVal.data.articles) {
      pageData.articles.items = newVal.data.articles.items || [];
      totalItems.value = newVal.data.articles.totalCount || 0;
    } else {
      pageData.articles.items = [];
      totalItems.value = 0;
    }
  },
  { immediate: true }
);

const loaded = computed(() => !articlesPending.value && articlesData.value);

watchEffect(() => {
  const defaultTitle = 'Moto Vehis - najlepszy portal motoryzacyjny w Polsce';
  const defaultDescription = 'Śledź na bieżąco wydarzenia ze świata motoryzacji w Polsce i na świecie. Premiery, testy samochodów, trendy w motoryzacji. Tylko u nas! Sprawdź';

  const currentPage = parseInt(route.query.page) || 1;
  const totalPages = Math.ceil(totalItems.value / itemsPerPage);

  // Kopia query do manipulacji
  const query = { ...route.query };

  // Generowanie prev i next
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage = currentPage < totalPages ? currentPage + 1 : null;

  const prevUrl = prevPage 
    ? `https://vehis.pl${route.path.split('?')[0]}?${new URLSearchParams({ ...query, page: prevPage }).toString()}`
    : null;

  const nextUrl = nextPage 
    ? `https://vehis.pl${route.path.split('?')[0]}?${new URLSearchParams({ ...query, page: nextPage }).toString()}`
    : null;

  const seoTitle = currentPage > 1 
    ? `${pageData.articles.items[0]?.category?.title} - strona ${currentPage}` 
    : pageData.articles.items[0]?.category?.title ?? defaultTitle;

  const seoDescription = pageData.articles.items[0]?.category?.seo_description ?? defaultDescription;

  const canonicalUrl = `https://vehis.pl${route.fullPath}`;

  useHead({
    title: seoTitle,
    meta: [
      { name: 'description', content: seoDescription },
      { property: 'og:title', content: seoTitle },
      { property: 'og:description', content: seoDescription },
      { property: 'og:type', content: 'article' },
      { property: 'og:image', content: 'https://vehis.pl/moto/assets/img/moto-vehis-logo-new.png' },
    ],
    link: [
      { rel: 'canonical', href: canonicalUrl },
      ...(prevUrl ? [{ rel: 'prev', href: prevUrl }] : []),
      ...(nextUrl ? [{ rel: 'next', href: nextUrl }] : []),
    ],
  });
});
function goToPage(newPage) {
  const basePath = route.path.split('?')[0];

  if (newPage === 1) {
    const { page, ...restQuery } = route.query;
    router.push({ path: basePath, query: { ...restQuery } });
  } else {
    router.push({ path: basePath, query: { ...route.query, page: newPage } });
  }
}
</script>

<template>
  <Container no-paddings>
    <div v-if="loaded">
      <LayoutColumns>
        <LayoutMainColumn noflex>
          <BlogBreadCrumbs
            :breadcrumbs="[
              { label: 'Strona główna', to: '/moto' },
              { label: 'Wyniki wyszukiwania', to: route.fullPath }
            ]"
          />
          <MainContentHeader class="font-extralight !text-left">
            Twoje wyszukiwanie: <span class="font-normal">{{ searchQuery }}</span>
          </MainContentHeader>
          <div
            v-for="(group, groupIndex) in Math.ceil(pageData.articles.items.length / 6)"
            :key="groupIndex"
          >
            <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 mb-6">
              <template
                v-for="(item, index) in pageData.articles.items.slice(
                  groupIndex * 6,
                  groupIndex * 6 + 6
                )"
                :key="`article-${groupIndex}-${index}`"
              >
                <BlogMainCard :index="index" :item="item" />
              </template>
            </div>
            <BannerMain
              v-if="groupIndex === 0 && (groupIndex + 1) * 6 < pageData.articles.items.length"
              :bannerName="'moto-wyszukiwarka-srodek-1440x300'"
              class="!mx-0 my-12"
            />
            <BannerMain
              v-if="groupIndex === 1 && (groupIndex + 1) * 6 < pageData.articles.items.length"
              :bannerName="'moto-wyszukiwarka-dol-1440x300'"
              class="!mx-0 my-12"
            />
          </div>
          <BlogPagination
            :total-items="totalItems"
            :items-per-page="itemsPerPage"
            :current-page.sync="currentPage"
            @page-changed="goToPage"
          />
        </LayoutMainColumn>
        <LayoutSideColumn class="hidden lg:block pb-4 !bg-transparent mt-6">
          <!-- <BlogFirstLeasingBanner /> -->
          <BannerSidebar :bannerSlot="'moto-wyszukiwarka-300x600'" class="my-6" />
        </LayoutSideColumn>
      </LayoutColumns>
      <!-- <BlogFirstLeasingBannerFixed/> -->
    </div>
    <div v-else>
      <p>Ładowanie wyników wyszukiwania...</p>
    </div>
  </Container>
</template>
